import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import "../styles/index.scss"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <section className="top">
        <h1 className="name">Alexandre Massé</h1>
        <h2 className="job">Front-end developer</h2>
        <div className="description">
          <p className="student">
            <span>Student at</span>
            <a
              href="https://www.gobelins.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gobelins
            </a>
          </p>
          <p className="work">
            <span>Working at</span>
            <a
              href="https://big-boss-studio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Big Boss Studio
            </a>
          </p>
        </div>
        <p className="projects">
          <span>Last projects</span>
          <a href="https://black-out.io">Black|Out</a>
          <a href="https://github.com/mineraux/make-bench-great-again">
            {"L'envers du décor"}
          </a>
          <a href="https://github.com/AlexandreMasse/hodos">Hodos</a>
        </p>
      </section>

      <nav className="socials">
        <a
          href="https://github.com/AlexandreMasse"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        <a
          href="https://twitter.com/Alexandre_Masse"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          href="https://flickr.com/photos/alexandre_masse"
          target="_blank"
          rel="noopener noreferrer"
        >
          Flickr
        </a>
        <a
          href="https://instagram.com/alexandre_mss"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          href="https://linkedin.com/in/alexandremasse/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          href="mailto:alexandre.masse94@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email
        </a>
      </nav>
    </Layout>
  )
}

export default IndexPage
